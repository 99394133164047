import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import { useAuthStore } from '~/stores/auth';
import { useCabinetStore } from '~/stores/cabinet';
import type { GlobalsStoreState } from '~/types/stores/globals';
import type {
  FetchCurrenciesResponse,
  AcceptTermsRequest,
} from '~/types/general/globals';
import type { NotificationsItem } from '~/types/components/General/Notifications';
import type { CashConfigType } from '~/types/general/cash';

export const useGlobalsStore = defineStore({
  id: 'globals',
  state: (): GlobalsStoreState => ({
    currencies: [],
    notifications: [],
    cashConfig: {},
    freeSpinsIntervalId: null,
    canonicalUrl: null,
  }),
  getters: {
    getCurrencies: (state) => state.currencies,
    getNotifications: (state) => state.notifications,
    getCashConfig: (state) => state.cashConfig,
    getCanonicalUrl: (state) => state.canonicalUrl,
   },
  actions: {
    async fetchCurrencies() {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.account}/currencies`) as FetchCurrenciesResponse;

        this.currencies = data.return;
      } catch(error) {
        throw error;
      }
    },
    async sendReferralLink(requestRef: string) {
      try {
        await useMyFetch().post(`${API_PREFIX.default}/affiliate/hit`, { query: { requestRef } });
      } catch(error) {
        console.error(error);
      }
    },
    setNotificationsFromStorage(notifications: NotificationsItem[]) {
      this.notifications = notifications;
    },
    setNotification(notification: NotificationsItem) {
      const notificationData = {
        ...notification,
        id: Date.now(),
        showing: true,
      };

      this.notifications.push(notificationData);

      if (notificationData.timeout === -1 || notificationData.isFreeSpins) {
        const notificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications);

        useLocalStorageObject.set(
          LOCAL_STORAGE.notifications,
          notificationsFromStorage?.length
            ? [...notificationsFromStorage, notificationData]
            : [notificationData],
        );
      }
    },
    removeNotification(notification: NotificationsItem) {
      this.notifications = this.notifications.filter((notif) => notif.id !== notification.id);

      if (notification.timeout === -1) {
        const filteredNotificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications)
          ?.filter((notif: NotificationsItem) => notif.id !== notification.id);

        if (filteredNotificationsFromStorage?.length) {
          useLocalStorageObject.set(LOCAL_STORAGE.notifications, filteredNotificationsFromStorage);
        } else {
          useLocalStorageObject.remove(LOCAL_STORAGE.notifications);
        }
      }
    },
    removeAllNotificationsExceptCookie() {
      this.notifications = this.notifications.filter((notif) => notif.isCookie);
      const cookieNotificationFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications)
        ?.filter((notif: NotificationsItem) => notif.isCookie);

      if (cookieNotificationFromStorage?.length) {
        useLocalStorageObject.set(LOCAL_STORAGE.notifications, cookieNotificationFromStorage);
      } else {
        useLocalStorageObject.remove(LOCAL_STORAGE.notifications);
      }
    },
    closeNotification(notification: NotificationsItem) {
      const closeIfExists = (notifArr: NotificationsItem[]) => {
        const index = notifArr.findIndex((item) => item.bonusId === notification.bonusId);

        if (index !== -1) {
          notifArr[index].showing = false;
        }
      };

      closeIfExists(this.notifications);

      if (notification.timeout === -1 || notification.isFreeSpins) {
        const notificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications);

        if (notificationsFromStorage?.length) {
          closeIfExists(notificationsFromStorage);
          useLocalStorageObject.set(LOCAL_STORAGE.notifications, notificationsFromStorage);
        }
      }
    },
    async acceptTerms() {
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(`${API_PREFIX.playerProfile}/accept-terms`) as AcceptTermsRequest;

        if (data.success) {
          authStore.userData.termsAccepted = data.success;
        }
      } catch (error) {
        throw(error);
      }
    },
    showTermsAndConditions() {
      const authStore = useAuthStore();

      authStore.userData.showTermsAndConditions = true;
    },
    /**
     * Polls the API every 2 seconds for up to 10 seconds to check if free spins have been applied.
     * If free spins are detected, it triggers a notification with the relevant details.
     * The polling stops either when free spins are found or after 5 attempts.
     *
     * @param {string} platform - The platform identifier to fetch the free spins count for.
     */
    async startFreeSpinsPolling(platform: string) {
      const { fetchFreeSpinsCount } = useCabinetStore();
      let timesRun = 0;

      if (this.freeSpinsIntervalId) {
        this.clearFreeSpinsInterval();
      }
      this.freeSpinsIntervalId = window.setInterval(async () => {
        timesRun += 1;
        const notificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications)  as NotificationsItem[];
        const freeSpinItem = await fetchFreeSpinsCount(platform);

        if (freeSpinItem || timesRun === 5) {
          this.clearFreeSpinsInterval();
        }

        if (freeSpinItem) {
          const hasSameNotification = notificationsFromStorage?.some((notif) => notif.bonusId === freeSpinItem.bonus_id);

          if (!hasSameNotification) {
            this.setNotification({
              dictionaryKeyTitle: 'general_free_spins',
              dictionaryKeyText: 'cabinet_balance_promocode_bonus_added_free_spins',
              dictionaryKeyTextProps: {
                count: freeSpinItem.prize,
                name: freeSpinItem.games[0].name,
              },
              isFreeSpins: true,
              bonusId: freeSpinItem.bonus_id,
              buttons: [
                {
                  class: 'text-none text-body-1 px-9 px-lg-5 j-btn j-btn--gradient-main',
                  dictionaryKey: 'general_play',
                  action: {
                    name: 'gameLink',
                    gamePrettyName: freeSpinItem.games[0].prettyName,
                  },
                },
              ],
            });
          }
        }
      }, 2000);
    },
    clearFreeSpinsInterval() {
      clearInterval(Number(this.freeSpinsIntervalId));
      this.freeSpinsIntervalId = null;
    },
    async fetchCashConfig(cashierType: string) {
      try {
        const staticStorageUrl = useBaseAppUrl().static;
        const authStore = useAuthStore();
        const { getUserData } = storeToRefs(authStore);

        this.cashConfig = (await useMyFetch().get(
          `${staticStorageUrl}/payment/${cashierType}-config.json?language=${getUserData.value.locale}`,
          { headers: { accept: 'application/json' } },
        )).data as CashConfigType;
      } catch (error) {
        throw(error);
      }
    },
    async fetchCanonicalUrlForSSR() {
      const url = '/api/canonical-url';
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        const data = await $fetch(url);

        this.canonicalUrl = data as string;

        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      };
    },
  },
});
